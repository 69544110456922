body {
  margin: 0;
  background: repeating-linear-gradient(
      -45deg,
      #202c42,
      #202c42 0.5pt,
      transparent 0,
      transparent 40pt
    ),
    repeating-linear-gradient(
      -45deg,
      #202c42,
      #202c42 0.5pt,
      transparent 0,
      transparent 26pt
    ),
    #192233;
  font-size: calc(12px + 0.35vw);
}

@media all and (min-width: 801px) {
  body {
    font-size: 14.8px;
  }
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.container-main {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 800px) 1fr;
  grid-template-rows: 50px auto auto auto auto auto;
}

.bar-links {
  display: grid;
  grid-gap: calc(15px + 1vw);
  margin: calc(12px + 0.8vw) calc(15px + 3vw);
}

.bar-links-left {
  grid-template-columns: repeat(4, auto);
  grid-area: 1 / 1 / 2 / 3;
  justify-self: self-start;
}

.bar-links-right {
  grid-template-columns: repeat(2, auto);
  grid-area: 1 / 2 / 2 / -1;
  justify-self: self-end;
}

.bar-links > div > a {
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  font-size: calc(1em + 0.35vw);
  color: white;
  text-decoration: none;
  border-bottom: 0.5px solid #48a9ea;
}

@media all and (min-width: 1201px) {
  .bar-links > div > a {
    font-size: 19.6px;
  }
}

.logo {
  grid-area: 2 / 2 / 3 / 3;
  margin: 40px;
  max-width: 640px;
  justify-self: center;
}

.logo-bloom {
  filter: blur(20px);
  opacity: 0.3;
  z-index: -1;
}

.button-try {
  grid-area: 3 / 2 / 4 / 3;
  justify-self: center;
  background-color: #24c85a;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: white;
  text-decoration: none;
  width: 160px;
  height: 50px;
  border-radius: 25px;
  margin-top: -25px;
  line-height: 50px;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.button-try:hover {
  background-color: #2fdb67;
}

.button-try:active {
  background-color: #24c85a;
}

.content {
  padding: 40px 20px;
}

.banner-features {
  grid-area: 3 / 1 / 4 / -1;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
  overflow: visible;
  z-index: -1;
}

.features {
  grid-area: 3 / 2 / 4 / -2;
}

.glyph-background {
  font-family: "Space Mono", monospace;
  position: relative;
  margin: 0;
  width: 0;
  height: 0;
  overflow: visible;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.illustration {
  display: grid;
  margin: 5em 0;
  text-align: center;
}

.sample {
  font-size: calc(1em + 6.8vw);
  font-weight: bold;
  margin-bottom: 0.3em;
}

@media all and (min-width: 801px) {
  .sample {
    font-size: 69.2px;
  }
}

.sample > span {
  display: inline-block;
}

.sample > span:hover {
  color: #765cf6;
  mix-blend-mode: multiply;
}

.jump {
  animation: JumpAnimation 900ms linear;
}

@keyframes JumpAnimation {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  1.78% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -3.239, 0, 1);
  }
  3.5% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -5.384, 0, 1);
  }
  6.95% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -7.864, 0, 1);
  }
  10.45% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -9.033, 0, 1);
  }
  13.9% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -9.562, 0, 1);
  }
  20.85% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -9.914, 0, 1);
  }
  27.81% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -9.984, 0, 1);
  }
  44.34% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -10, 0, 1);
  }
  44.44% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -10, 0, 1);
  }
  45.67% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -5.717, 0, 1);
  }
  46.84% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.89, 0, 1);
  }
  48% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 2.366, 0, 1);
  }
  49.17% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 3.35, 0, 1);
  }
  50.51% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 2.375, 0, 1);
  }
  51.84% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.623, 0, 1);
  }
  53.18% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.67, 0, 1);
  }
  54.45% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -1.05, 0, 1);
  }
  55.56% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.832, 0, 1);
  }
  57.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.198, 0, 1);
  }
  59.74% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.329, 0, 1);
  }
  65.02% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.103, 0, 1);
  }
  70.3% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.032, 0, 1);
  }
  75.64% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.01, 0, 1);
  }
  80.93% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.003, 0, 1);
  }
  86.21% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.001, 0, 1);
  }
  91.49% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  96.83% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.token {
  font-size: 1.6em;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 40px;
  text-align: center;
  justify-content: space-evenly;
}

.feature {
  margin: 0 20px;
}

.example {
  grid-area: 4 / 2 / 5 / -2;
}

.code-block {
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #253451;
  padding: 0.8em 1.3em;
  border-radius: 3px;
  color: #dde1f9;
}

.ident {
  color: #51d3f6;
}

.string {
  color: #7ef69d;
}

.op {
  color: #ff926e;
}

.mod {
  color: #697bdb;
}

.comment {
  color: #a5adc6;
}

.banner-benchmark {
  grid-area: 5 / 1 / 6 / -1;
  background-color: #2485cb;
  background-image: linear-gradient(
    67deg,
    #2485cb 0%,
    #08aeea 37%,
    #11dd80 100%
  );
  overflow: visible;
  z-index: -1;
}

.benchmark {
  grid-area: 5 / 2 / 6 / -2;
}

.chart {
  display: grid;
  grid-template-columns: 6em repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr) 1.5em;
  height: calc(17em + 5vw);
}

@media all and (min-width: 801px) {
  .chart {
    height: 291.317px;
  }
}

.chart-label {
  justify-self: self-end;
  align-self: center;
}

.chart-label > p {
  margin-right: 0.8em;
}

.chart-border {
  border-left: 0.5px dashed white;
  width: 0.5px;
}

.chart-border-1 {
  grid-area: 1 / 2 / -1 / 3;
  border-left: 0.5px solid white;
  z-index: 1;
}

.chart-border-2 {
  grid-area: 1 / 3 / -1 / 4;
}

.chart-border-3 {
  grid-area: 1 / 4 / -1 / 5;
}

.chart-bar {
  overflow: hidden;
  align-self: center;
}

.chart-bar > div {
  box-sizing: border-box;
  margin-left: -25%;
  padding-left: 25%;
  text-align: center;
  border-radius: 0 3px 3px 0;
  background-color: #1245ff;
}

.chart-bar > div > p {
  margin: 0;
  padding: 0.3em;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.chart-bar > div:hover > p {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.chart-bar-pest {
  grid-area: 1 / 2 / 2 / -1;
}

.chart-bar-pest > div {
  width: calc(15% + 25%); /* negative margin */
  background-color: #ff007b;
}

.chart-bar-nom {
  grid-area: 2 / 2 / 3 / -1;
}

.chart-bar-nom > div {
  width: calc(36.6% + 25%); /* negative margin */
}

.chart-bar-serde {
  grid-area: 3 / 2 / 4 / -1;
}

.chart-bar-serde > div {
  width: calc(47.2% + 25%); /* negative margin */
}

.chart-bar-hidden {
  opacity: 0;
}

.chart-bar-bounce {
  animation:
    BounceAnimation 2.5s linear,
    FadeInAnimation 0.4s ease;
  animation-delay: 0.2s, 0.2s;
  animation-fill-mode: backwards;
}

@keyframes FadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes BounceAnimation {
  0% {
    transform: matrix3d(0.75, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  1.4% {
    transform: matrix3d(0.849, 0, 0, 0, 0, 0.879, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  2.7% {
    transform: matrix3d(0.985, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4% {
    transform: matrix3d(1.091, 0, 0, 0, 0, 1.072, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  5.31% {
    transform: matrix3d(1.128, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  6.81% {
    transform: matrix3d(1.09, 0, 0, 0, 0, 1.072, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  8.21% {
    transform: matrix3d(1.018, 0, 0, 0, 0, 1.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.61% {
    transform: matrix3d(0.958, 0, 0, 0, 0, 0.966, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.01% {
    transform: matrix3d(0.936, 0, 0, 0, 0, 0.949, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.91% {
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.993, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  16.72% {
    transform: matrix3d(1.032, 0, 0, 0, 0, 1.025, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  22.52% {
    transform: matrix3d(0.984, 0, 0, 0, 0, 0.987, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  28.23% {
    transform: matrix3d(1.008, 0, 0, 0, 0, 1.006, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  33.93% {
    transform: matrix3d(0.996, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  39.64% {
    transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  45.35% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  51.05% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  56.76% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  62.56% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  68.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  73.97% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  79.68% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.39% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  91.09% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  96.8% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.chart-value {
  align-self: center;
  opacity: 0.7;
  font-size: 0.8em;
  margin-left: 0.5em;
}

.chart-value-0 {
  grid-area: 5 / 2 / 6 / 3;
}

.chart-value-250 {
  grid-area: 5 / 3 / 6 / 4;
}

.chart-value-500 {
  grid-area: 5 / 4 / 6 / 5;
}

.editor {
  grid-area: 6 / 2 / 7 / -2;
}
.wide-editor {
  grid-area: 6 / 1 / 7 / 4;
  padding-top: 0;
  padding-bottom: 0;
}
.gutter:hover {
  cursor: col-resize;
}
.flex-editor {
  display: flex;
  flex-direction: row;
}
.output-wrapper {
  display: grid;
  grid-template-rows: 100%;
}
.editor-grid > div {
  margin-bottom: 20px;
}
.wide-input {
  grid-template-columns: none !important;
  grid-template-rows: 20fr 1fr;
}
.editor-input {
  display: grid;
  grid-template-columns: 4fr 1fr;
  background-color: #253451;
  border-radius: 3px;
}

.editor-input-text {
  background-color: initial;
  border-radius: 0;
}

.editor-input-select {
  font-family: "Space Mono", monospace;
  font-variant-ligatures: none;
  font-size: 1em;
  text-align-last: center;
  background: #3f4e6b url("assets/dropdown.svg") no-repeat;
  background-position: 90% 50%;
  background-blend-mode: soft-light;
  border: 1.5px solid #57627a;
  border-radius: 0 3px 3px 0;
  color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.editor-input-select:disabled {
  background: #3a475f url("assets/dropdown.svg") no-repeat;
  background-position: 90% 50%;
  background-blend-mode: soft-light;
  color: #7a879c;
}

.cm-s-pest.CodeMirror {
  font-family: "Space Mono", monospace;
  font-variant-ligatures: none;
  font-size: 1em;
  line-height: 1.5em;
  background: #253451;
  padding: 0.8em 1.3em;
  border-radius: 3px;
  color: white;
}

.cm-s-pest .CodeMirror-cursor {
  border-left: 1px solid white;
}

.cm-s-pest span.cm-comment {
  color: #a5adc6;
}
.cm-s-pest span.cm-operator {
  color: #ff926e;
}
.cm-s-pest span.cm-operator-2 {
  color: #697bdb;
}
.cm-s-pest span.cm-number {
  color: #aa759f;
}
.cm-s-pest span.cm-string {
  color: #7ef69d;
}
.cm-s-pest span.cm-constiable {
  color: #4ea8d8;
}
.cm-s-pest span.cm-constiable-2 {
  color: white;
}

.cm-s-pest span.cm-tag {
  color: #f6dc51;
}
.cm-s-pest span.cm-error {
  background: #ac4142;
  color: #b0b0b0;
}

.cm-s-pest .CodeMirror-placeholder {
  color: #999 !important;
}

.cm-s-pest .CodeMirror-lint-mark-error {
  background-image: none;
  border-bottom: 1px dotted #f9ff3d;
}

.CodeMirror-lint-tooltip {
  background-color: white !important;
  font-family: "Space Mono", monospace !important;
  font-variant-ligatures: none !important;
  font-size: 1em !important;
  line-height: 1.5em !important;
  padding: 0.8em 1.3em !important;
  padding-left: 0 !important;
  border: none !important;
  border-left: 2em solid #ff3d3d !important;
  border-radius: 3px !important;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2) !important;
}

.CodeMirror-lint-marker-error,
.CodeMirror-lint-message-error {
  background-image: none !important;
}

h1 {
  margin: 1.2em 0;
  font-family: "Quicksand", sans-serif;
  font-size: 2.1em;
  color: white;
}

h2 {
  margin: 1.2em 0;
  font-family: "Quicksand", sans-serif;
  font-size: 1.8em;
  font-weight: lighter;
  color: white;
}

p {
  font-family: "Quicksand", sans-serif;
  font-size: 1.15em;
  line-height: 1.65em;
  color: white;
}

a {
  font-family: "Quicksand", sans-serif;
  font-size: 1.15em;
  line-height: 1.65em;
  color: white;
}

code {
  font-family: "Space Mono", monospace;
  font-variant-ligatures: none;
  font-size: 1em;
  line-height: 1.5em;
  color: white;
}

button {
  justify-self: center;
  background-color: #616e86;
  font-family: "Quicksand", sans-serif;
  font-size: 1em;
  margin-bottom: 20px;
  padding: 0.8em 1em;
  color: white;
  border: 0;
  border-radius: 2px;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  transition: width 400ms ease-in-out;
}

button + button {
  margin-left: 20px;
}

button:hover {
  background-color: #7b89a5;
}

button:active {
  background-color: #596375;
}

button:disabled {
  background-color: #596375;
}

textarea {
  resize: none;
  font-family: "Space Mono", monospace;
  font-variant-ligatures: none;
  font-size: 1em;
  line-height: 1.5em;
  background-color: #253451;
  padding: 0.8em 1.3em;
  border: none;
  border-radius: 3px;
  color: white;
}
